import * as React from "react";
import { Box, Container, Typography } from "@mui/material";

export default function NotFound() {
  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center",
      }}
    >
      <Container maxWidth="md">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography
            align="center"
            color="textPrimary"
            variant="h1"
            sx={{
              p: 2,
              borderRight: "1px solid rgba(0, 0, 0,.3)",
            }}
          >
            404
          </Typography>
          <Typography
            align="center"
            color="textPrimary"
            variant="subtitle2"
            sx={{
              p: 2,
              mt: 1.5,
            }}
          >
            This page could not be found.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}
