import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { styled } from "@mui/material/styles";
import backgroundImage from "../assets/background.jpg";
import Link from "@mui/material/Link";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { LanguageContext } from "../contexts/LanguageContext";

import logoImage from "../assets/MOV_Color_RGB_Horizontal.svg";
import en_gb from "../assets/gb-nir.svg";
import de_de from "../assets/de.svg";
import { Button, ButtonGroup } from "@mui/material";
import { useTranslation } from "react-i18next";

import { createSvgIcon } from "@mui/material/utils";

const GermanFlagIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="flag-icons-de"
    viewBox="0 0 640 480"
  >
    <path fill="#fc0" d="M0 320h640v160H0z" />
    <path fill="#000001" d="M0 0h640v160H0z" />
    <path fill="red" d="M0 160h640v160H0z" />
  </svg>,
  "German"
);

const UkFlagIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="flag-icons-gb"
    viewBox="0 0 640 480"
  >
    <path fill="#012169" d="M0 0h640v480H0z" />
    <path
      fill="#FFF"
      d="m75 0 244 181L562 0h78v62L400 241l240 178v61h-80L320 301 81 480H0v-60l239-178L0 64V0z"
    />
    <path
      fill="#C8102E"
      d="m424 281 216 159v40L369 281zm-184 20 6 35L54 480H0zM640 0v3L391 191l2-44L590 0zM0 0l239 176h-60L0 42z"
    />
    <path fill="#FFF" d="M241 0v480h160V0zM0 160v160h640V160z" />
    <path fill="#C8102E" d="M0 193v96h640v-96zM273 0v480h96V0z" />
  </svg>,
  "UK"
);

const StyledImg = styled("img")({});
const pages = [
  {
    name: "home",
    text: "Home",
    path: "home",
  },
  {
    name: "customers",
    text: "Our Customers",
    path: "our-customers",
  },
  {
    name: "offers",
    text: "Our Offers",
    path: "our-offer",
  },
  {
    name: "aboutUs",
    text: "About Us",
    path: "about-us",
  },
  {
    name: "learnings",
    text: "Learnings",
    path: "learnings",
  },
  {
    name: "contactUs",
    text: "Contact Us",
    path: "contact-us",
  },
];

function ResponsiveAppBar() {
  const { t } = useTranslation();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  const menuTexts = t("menu", { returnObjects: true });
  const langContext = React.useContext(LanguageContext);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (page) => {
    setAnchorElNav(null);
    if (page) {
      navigate(page);
    }
  };

  return (
    <AppBar
      elevation={0}
      position="fixed"
      sx={{
        padding: "24px 32px !important",
        bgcolor: "green",
        borderTop: "3px solid #9F1483",
        alignItems: "stretch",
        [theme.breakpoints.up("md")]: {
          alignItems: "center",
        },
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      <Toolbar
        disableGutters
        sx={{
          display: "flex",
          minHeight: "50px !important",
        }}
      >
        <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
          <IconButton
            size="large"
            aria-label="Main Menu"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon color="success" />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClick={() => handleCloseNavMenu(null)}
            sx={{
              display: { xs: "block", md: "none" },
            }}
          >
            {pages.map((page) => (
              <MenuItem
                key={page.path}
                onClick={() => handleCloseNavMenu(page.path)}
              >
                <Typography textAlign="center">
                  {menuTexts[page.name]}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
        <Box
          sx={{
            mr: 2,
            display: { xs: "flex", md: "none" },
            flexGrow: 1,
            color: "inherit",
            justifyContent: "left",
          }}
        >
          <RouterLink to="/">
            <StyledImg
              src={logoImage}
              sx={{
                height: 50,
                [theme.breakpoints.down("sm")]: {
                  height: 35,
                },
              }}
            />
          </RouterLink>
          <ButtonGroup
            sx={{
              ml: 2,
              height: "40px",
            }}
            disableElevation
            color="success"
            variant="outlined"
          >
            <Button
              onClick={() => langContext.changeUserLanguage("de")}
              sx={{
                pr: 0,
              }}
              startIcon={<GermanFlagIcon />}
            />
            <Button
              onClick={() => langContext.changeUserLanguage("en")}
              sx={{
                pr: 0,
              }}
              startIcon={<UkFlagIcon />}
            />
          </ButtonGroup>
        </Box>

        <Box
          sx={{
            mr: 2,
            display: { xs: "none", md: "flex" },
          }}
        >
          <RouterLink to="/">
            <StyledImg
              src={logoImage}
              sx={{
                height: 50,
              }}
            />
          </RouterLink>
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            display: { xs: "none", md: "flex" },
            width: "80%",
          }}
        >
          <List
            sx={{
              display: "flex",
            }}
          >
            {pages.map((page) => (
              <ListItem
                key={page.path}
                sx={{
                  [theme.breakpoints.between("sm", "lg")]: {
                    width: "100px",
                  },
                  width: "140px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Link
                  href={page.path}
                  component="button"
                  variant="body2"
                  underline={
                    location.pathname.slice(1) === "" ||
                    location.pathname.slice(1) === page.path
                      ? "always"
                      : "hover"
                  }
                  sx={{
                    color: "black",
                    fontSize: "0.8em",
                    fontWeight: "500",
                    "&.MuiLink-underlineAlways": {
                      textDecorationColor: "#AEA59C !important",
                    },
                    "&.MuiLink-underlineHover": {
                      textDecorationColor: "#AEA59C !important",
                    },
                    "&:hover": {
                      fontWeight: 600,
                      color: "green",
                    },
                    ...((location.pathname.slice(1) === "" ||
                      location.pathname.slice(1) === page.path) && {
                      fontWeight: 600,
                      color: "green",
                    }),
                  }}
                  onClick={() => handleCloseNavMenu(page.path)}
                >
                  {menuTexts[page.name]}
                </Link>
              </ListItem>
            ))}
          </List>
          <ButtonGroup
            sx={{
              height: "40px",
              width: "100px",
            }}
            disableElevation
            color="success"
            variant="outlined"
          >
            <Button
              onClick={() => langContext.changeUserLanguage("de")}
              sx={{
                pr: 0,
              }}
              startIcon={<GermanFlagIcon />}
            />
            <Button
              onClick={() => langContext.changeUserLanguage("en")}
              sx={{
                pr: 0,
              }}
              startIcon={<UkFlagIcon />}
            />
          </ButtonGroup>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
export default ResponsiveAppBar;
