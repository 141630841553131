import * as React from "react";
import ContentHeader from "./ContentHeader";
import ContentText from "./ContentText";

export default function Content({ ...props }) {
  const { headerText, theme, textJson } = props;
  return (
    <>
      <ContentHeader theme={theme} headerText={headerText} />
      <ContentText theme={theme}>
        {Object.keys(textJson).map(
          (x) =>
            textJson[x] instanceof Object && (
              <React.Fragment key={`header-${textJson[x].title}`}>
                <strong key={textJson[x].title}>{textJson[x].title}</strong>
                <br />
                {textJson[x].items.map((y) => (
                  <React.Fragment key={`child-${y.substring(0, 20)}`}>
                    {y}
                    <br />
                  </React.Fragment>
                ))}
              </React.Fragment>
            )
        )}
      </ContentText>
    </>
  );
}
