import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export default function ContentHeader({ ...props }) {
  const { theme, headerText } = props;
  return (
    <Grid
      item
      xs={12}
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          paddingBottom: 1,
          width: "60%",
          [theme.breakpoints.down("md")]: {
            width: "90%",
          },
        }}
      >
        <Typography
          sx={{
            textAlign: "justify",
            fontSize: "18px",
            color: "#3B505C",
            fontWeight: 500,
          }}
        >
          {headerText}
        </Typography>
      </Box>
    </Grid>
  );
}
