import * as React from "react";
import Box from "@mui/material/Box";

export default function PageHeader({ ...props }) {
  const {
    color,
    text,
    borderColor,
  } = props;
  return (
    <Box
      sx={{
        fontSize: "40px",
        fontWeight: "700",
        color: color,
        borderBottom: `3px solid ${borderColor}`,
        height: "60px",
        marginBottom: 5,
      }}
    >
      {text}
    </Box>
  );
}
