import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import backgroundImage from "../assets/background.jpg";
import mail from "../assets/mail.svg";
import linkedin from "../assets/linkedin.svg";
import Link from "@mui/material/Link";
import Divider from "@mui/material/Divider";
import { PageHeader } from "../components";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

const StyledImg = styled("img")({});

export default function ContactUs() {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          paddingTop: "140px",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          backgroundImage: `url(${backgroundImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100%",
          paddingBottom: "50px",
          height: "94.5vh",
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            [theme.breakpoints.up("lg")]: {
              width: "80%",
            },
          }}
        >
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <PageHeader
                borderColor="#AEA59C"
                color="#EE7103"
                text={t("contactUs.title")}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                padding: 5,
                borderRadius: "15px",
                background: "linear-gradient(to left bottom, #A45816, #2C3037)",
                justifyContent: "center",
                width: "70%",
                [theme.breakpoints.down("md")]: {
                  width: "95%",
                },
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      color: "white",
                      fontWeight: 500,
                      fontSize: 18,
                      p: 3,
                    }}
                  >
                    {t("contactUs.subTitle")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Link href="https://www.linkedin.com/in/siva-subramaniyam/">
                        <StyledImg src={linkedin} alt="linkedin" width="70%" />
                      </Link>
                      <Typography>
                        <Link
                          underline="hover"
                          sx={{
                            color: "white",
                            fontWeight: 300,
                            fontSize: 16,
                          }}
                          href="https://www.linkedin.com/in/siva-subramaniyam/"
                        >
                          linkedin/siva-subramaniyam
                        </Link>
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        p: 2,
                      }}
                    >
                      <Divider
                        sx={{
                          opacity: 3,
                          "&.MuiDivider-root": {
                            borderColor: "rgba(255,255,255, 0.5)",
                          },
                        }}
                        orientation="vertical"
                        variant="middle"
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Link href="mailto:connect@mapofvita.org">
                        <StyledImg src={mail} alt="mail" width="70%" />
                      </Link>
                      <Typography>
                        <Link
                          underline="hover"
                          sx={{
                            color: "white",
                            fontWeight: 300,
                            fontSize: 16,
                          }}
                          href="mailto:connect@mapofvita.org"
                        >
                          connect@mapofvita.org
                        </Link>
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
