import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Contents from "./Contents";
import { useTranslation } from "react-i18next";
import backgroundImage from "../../assets/background.jpg";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import { PageHeader, Content } from "../../components";

export default function Learnings() {
  const { t } = useTranslation();
  const wisdom = t("learnings.topicsAndQuestions.wisdom", {
    returnObjects: true,
  });
  const changemakers = t("learnings.topicsAndQuestions.changemakers", {
    returnObjects: true,
  });
  const general = t("learnings.topicsAndQuestions.general", {
    returnObjects: true,
  });
  
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          paddingTop: "140px",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          backgroundImage: `url(${backgroundImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100%",
          paddingBottom: "50px",
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            [theme.breakpoints.up("lg")]: {
              width: "80%",
            },
          }}
        >
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <PageHeader
                borderColor="#AEA59C"
                color="#31499E"
                text={t("learnings.title")}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box>
              <Typography
                gutterBottom
                sx={{
                  fontSize: "28px",
                  fontWeight: "500",
                  color: "#432918",
                }}
              >
                {t("learnings.practices.title")}
              </Typography>
            </Box>
            <Box
              sx={{
                p: 3,
                width: "60%",
                [theme.breakpoints.down("md")]: {
                  width: "90%",
                  p: 2,
                },
              }}
            >
              <Typography
                sx={{
                  textAlign: "justify",
                  fontSize: "16px",
                  color: "#3B505C",
                }}
              >
                {t("learnings.practices.text")}
              </Typography>
            </Box>
            <Box
              sx={{
                p: 1,
                width: "60%",
                [theme.breakpoints.down("md")]: {
                  width: "90%",
                },
              }}
            >
              <Typography
                sx={{
                  textAlign: "justify",
                  [theme.breakpoints.down("md")]: {
                    textAlign: "center",
                  },
                  fontSize: "20px",
                  fontWeight: 600,
                  lineHeight: "32px",
                  color: "#3B505C",
                }}
              >
                {t("learnings.practices.sub1")}
                <br />
                {t("learnings.practices.sub2")}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                p: 1,
                width: "60%",
                [theme.breakpoints.down("md")]: {
                  width: "90%",
                },
              }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "28px",
                  fontWeight: "500",
                  color: "#432918",
                }}
              >
                {t("learnings.contents.title")}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Contents theme={theme} />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "60%",
                [theme.breakpoints.down("md")]: {
                  width: "90%",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: "24px",
                  color: "#3B505C",
                  fontWeight: "700",
                }}
              >
                {t("learnings.topicsAndQuestions.title")}
              </Typography>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                paddingBottom: 1,
                width: "60%",
                [theme.breakpoints.down("md")]: {
                  width: "90%",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "500",
                  fontStyle: "italic",
                  textAlign: "left",
                }}
              >
                {t("learnings.topicsAndQuestions.subTitle")}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "500",
                textAlign: "left",
                width: "60%",
                [theme.breakpoints.down("md")]: {
                  width: "90%",
                },
              }}
            >
              {t("learnings.topicsAndQuestions.subTitle2")}
            </Typography>
          </Grid>
          <Content
            theme={theme}
            headerText={t("learnings.topicsAndQuestions.wisdom.title")}
            textJson={wisdom}
          />
          <Content
            theme={theme}
            headerText={t("learnings.topicsAndQuestions.changemakers.title")}
            textJson={changemakers}
          />

          <Content
            theme={theme}
            headerText={t("learnings.topicsAndQuestions.general.title")}
            textJson={general}
          />
        </Grid>
      </Box>
    </>
  );
}
