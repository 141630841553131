import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import customers from "../assets/customers.png";
import { styled } from "@mui/material/styles";
import backgroundImage from "../assets/background.jpg";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import { PageHeader } from "../components";
import { useTranslation } from "react-i18next";

const StyledImg = styled("img")({});

export default function Customers() {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <>
      <Box
        sx={{
          paddingTop: "140px",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          backgroundImage: `url(${backgroundImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100%",
          paddingBottom: "50px",
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            [theme.breakpoints.up("lg")]: {
              width: "80%",
            },
          }}
        >
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <PageHeader
                borderColor="#AEA59C"
                color="#31499E"
                text={t("customers.title")}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "65%",
            }}
          >
            <StyledImg
              src={customers}
              alt="my Image"
              sx={{
                width: "95%",
                [theme.breakpoints.down("md")]: {
                  width: "75%",
                },
                margin: "2%",
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "80%",
              [theme.breakpoints.down("md")]: {
                width: "95%",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                p: 4,
              }}
            >
              <Typography
                gutterBottom
                sx={{
                  fontSize: "28px",
                  fontWeight: "500",
                  color: "#432918",
                }}
              >
                {t("customers.individuals.subTitle")}
              </Typography>
              <Typography
                gutterBottom
                sx={{
                  textAlign: "justify",
                  fontSize: "16px",
                  color: "#3B505C",
                  width: "90%",
                  [theme.breakpoints.up("lg")]: {
                    width: "60%",
                  },
                  paddingBottom: "16px",
                }}
              >
                <strong>
                  - {t("customers.individuals.wisdomSeekerTitle")}
                </strong>{" "}
                {t("customers.individuals.wisdomSeekerDesc")}
                <br />
                <br />
                <strong>
                  - {t("customers.individuals.changeMakerTitle")}
                </strong>{" "}
                {t("customers.individuals.changeMakerDesc")}
                <br />
                <br />
                <strong>
                  - {t("customers.individuals.changeSeekerTitle")}
                </strong>{" "}
                {t("customers.individuals.changeSeekerDesc")}
              </Typography>
              <Typography
                gutterBottom
                sx={{
                  fontSize: "28px",
                  fontWeight: "500",
                  color: "#432918",
                }}
              >
                {t("customers.initiatives.subTitle")}
              </Typography>
              <Typography
                gutterBottom
                sx={{
                  textAlign: "justify",
                  fontSize: "16px",
                  color: "#3B505C",
                  width: "90%",
                  [theme.breakpoints.up("lg")]: {
                    width: "60%",
                  },
                  paddingBottom: "16px",
                }}
              >
                {t("customers.initiatives.desc")}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
