import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ArticleIcon from "@mui/icons-material/Article";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";

export default function Contents({ ...props }) {
  const { t } = useTranslation();
  const wisdomItems = t("learnings.contents.wisdom.items", { returnObjects: true });
  const changeMakerItems = t("learnings.contents.changemakers.items", { returnObjects: true });
  const { theme } = props;
  return (
    <Box
      sx={{
        paddingBottom: 1,
        display: "flex",
        width: "60%",
        [theme.breakpoints.down("md")]: {
          width: "90%",
        },
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            sx={{
              [theme.breakpoints.down("md")]: {
                width: "90%",
              },
            }}
          >
            <Typography
              sx={{
                textAlign: "justify",
                fontSize: "18px",
                color: "#3B505C",
                fontWeight: "400",
                [theme.breakpoints.down("md")]: {
                  textAlign: "center",
                },
              }}
            >
              {t("learnings.contents.wisdom.title")}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              [theme.breakpoints.down("md")]: {
                display: "flex",
                justifyContent: "center",
              },
            }}
          >
            <List dense>
              <ListItem>
                <ListItemIcon>
                  <ArticleIcon color="success" />
                </ListItemIcon>
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontStyle: "italic",
                  }}
                >
                  {wisdomItems[0]}
                </Typography>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <ArticleIcon color="success" />
                </ListItemIcon>
                <ListItemText primary={wisdomItems[1]} />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <ArticleIcon color="success" />
                </ListItemIcon>
                <ListItemText primary={wisdomItems[2]} />
              </ListItem>
            </List>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              [theme.breakpoints.down("md")]: {
                display: "flex",
                justifyContent: "center",
              },
            }}
          >
            <List dense>
              <ListItem>
                <ListItemIcon>
                  <ArticleIcon color="success" />
                </ListItemIcon>
                <Typography
                  sx={{
                    fontWeight: "500",
                  }}
                >
                  {wisdomItems[3]}
                </Typography>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <ArticleIcon color="success" />
                </ListItemIcon>
                <ListItemText primary={wisdomItems[4]} />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <ArticleIcon color="success" />
                </ListItemIcon>
                <ListItemText primary={wisdomItems[5]} />
              </ListItem>
            </List>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              [theme.breakpoints.down("md")]: {
                width: "70%",
              },
            }}
          >
            <Typography
              sx={{
                textAlign: "justify",
                fontSize: "18px",
                color: "#3B505C",
                fontWeight: "400",
                [theme.breakpoints.down("md")]: {
                  textAlign: "center",
                },
              }}
            >
              {t("learnings.contents.changemakers.title")}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              [theme.breakpoints.down("md")]: {
                display: "flex",
                justifyContent: "center",
                width: "90%",
              },
            }}
          >
            <List dense>
              <ListItem>
                <ListItemIcon>
                  <ArticleIcon color="success" />
                </ListItemIcon>
                <Typography
                  sx={{
                    fontWeight: "500",
                  }}
                >
                  {changeMakerItems[0]}
                </Typography>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <ArticleIcon color="success" />
                </ListItemIcon>
                <Typography
                  sx={{
                    fontWeight: "500",
                  }}
                >
                  {changeMakerItems[1]}
                </Typography>
              </ListItem>
            </List>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              [theme.breakpoints.down("md")]: {
                width: "60%",
              },
            }}
          >
            <Typography
              sx={{
                textAlign: "justify",
                fontSize: "18px",
                color: "#3B505C",
                fontWeight: "400",
                [theme.breakpoints.down("md")]: {
                  textAlign: "center",
                },
              }}
            >
              {t("learnings.contents.general.title")}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
