import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import backgroundImage from "../assets/background.jpg";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import { PageHeader } from "../components";
import offers from "../assets/offers.png";
import { useTranslation } from "react-i18next";

const StyledImg = styled("img")({});

export default function Offer() {
  const { t } = useTranslation();
  const desc = t("offer.initiatives.desc", { returnObjects: true });

  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          paddingTop: "140px",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          backgroundImage: `url(${backgroundImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100%",
          paddingBottom: "50px",
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            [theme.breakpoints.up("lg")]: {
              width: "80%",
            },
          }}
        >
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <PageHeader
                borderColor="#AEA59C"
                color="#00A9EB"
                text={t("offer.title")}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "400",
                color: "#432918",
                pr: 3,
                pl: 3,
              }}
            >
              {t("offer.subTitle")}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "65%",
            }}
          >
            <StyledImg
              src={offers}
              alt="Offers Image"
              sx={{
                width: "95%",
                [theme.breakpoints.down("md")]: {
                  width: "75%",
                },
                margin: "2%",
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "80%",
              [theme.breakpoints.down("md")]: {
                width: "95%",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                p: 4,
              }}
            >
              <Typography
                gutterBottom
                sx={{
                  fontSize: "28px",
                  fontWeight: "500",
                  color: "#432918",
                }}
              >
                {t("offer.individuals.subTitle")}
              </Typography>
              <Typography
                gutterBottom
                sx={{
                  textAlign: "justify",
                  fontSize: "16px",
                  color: "#3B505C",
                  width: "90%",
                  [theme.breakpoints.up("lg")]: {
                    width: "80%",
                  },
                  paddingBottom: "16px",
                }}
              >
                <strong>
                  - {t("offer.individuals.internalTransformationTitle")}
                </strong>{" "}
                {t("offer.individuals.internalTransformationDesc")}
                <br />
                <br />
                <strong>
                  - {t("offer.individuals.selflessServicesTitle")}
                </strong>{" "}
                {t("offer.individuals.selflessServicesDesc")}
                <br />
                <br />
                <strong>
                  - {t("offer.individuals.meaningfulConnectionsTitle")}
                </strong>{" "}
                {t("offer.individuals.meaningfulConnectionsDesc")}
              </Typography>
              <Typography
                gutterBottom
                sx={{
                  fontSize: "28px",
                  fontWeight: "500",
                  color: "#432918",
                }}
              >
                {t("offer.initiatives.subTitle")}
              </Typography>
              <Typography
                gutterBottom
                sx={{
                  textAlign: "justify",
                  fontSize: "16px",
                  color: "#3B505C",
                  width: "90%",
                  [theme.breakpoints.up("lg")]: {
                    width: "80%",
                  },
                  paddingBottom: "16px",
                }}
              >
                {desc.map((x) => (
                  <React.Fragment key={x.substring(0,10)}>
                    - {x}
                    <br />
                    <br />
                  </React.Fragment>
                ))}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
