import * as React from "react";
import { useRoutes } from "react-router-dom";
import routes from "./routes";
import backgroundImage from "./assets/background.jpg";
import { styled } from "@mui/material/styles";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import { LanguageContext } from "./contexts/LanguageContext";
import { SelectedLangContext } from "./contexts/SelectedLangContext";
import { useTranslation } from "react-i18next";

const WrapperDiv = styled("div")({
  height: "100vh",
  position: "unset",
  backgroundImage: `url(${backgroundImage})`,
});

export default function App() {
  const { i18n } = useTranslation();

  const [language, setLanguage] = React.useState("de");

  React.useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);

  const changeUserLanguage = React.useMemo(
    () => ({
      changeUserLanguage: (input) => {
        setLanguage(input);
      },
    }),
    []
  );

  const routing = useRoutes(routes());
  return (
    <>
      <LanguageContext.Provider value={changeUserLanguage}>
        <SelectedLangContext.Provider value={language}>
          <PerfectScrollbar>
            <WrapperDiv>{routing}</WrapperDiv>
          </PerfectScrollbar>
        </SelectedLangContext.Provider>
      </LanguageContext.Provider>
    </>
  );
}
