import * as React from "react";
import { Navigate } from "react-router-dom";
import MainLayout from "../layout/mainLayout";
import {
  AboutUs,
  ContactUs,
  Customers,
  Home,
  Learnings,
  NotFound,
  Offers,
  Volunteers,
} from "../pages";

const routes = () => [
  {
    path: "/",
    element: <MainLayout />,
    children: [
      { path: "404", element: <NotFound /> },
      { path: "/", element: <Navigate to="/home" replace /> },
      { path: "/home", element: <Home /> },
      { path: "/about-us", element: <AboutUs /> },
      { path: "/contact-us", element: <ContactUs /> },
      { path: "/our-customers", element: <Customers /> },
      { path: "/our-offer", element: <Offers /> },
      { path: "/learnings", element: <Learnings /> },
      { path: "/volunteers", element: <Volunteers /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
];

export default routes;
