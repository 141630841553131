import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import founder from "../assets/siva-founder.jpg";
import vision from "../assets/vision.png";
import aspiration from "../assets/aspiration.png";
import purpose from "../assets/purpose.png";
import backgroundImage from "../assets/background.jpg";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import { PageHeader } from "../components";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { useTranslation } from "react-i18next";

import ss from "../assets/SelvakumarSubramaniam.svg";
import kn from "../assets/KarthibanNithyakumar.svg";
import kf from "../assets/KatharinaFlorian.svg";
import pd from "../assets/PradeeprajDevaraj.svg";
import rr from "../assets/RaissaReinfeld.svg";

const volunteers = [
  {
    name: "Katharina Florian",
    image: kf,
  },
  {
    name: "Pradeepraj Devaraj",
    image: pd,
  },
  {
    name: "Selvakumar Subramaniam",
    image: ss,
  },
  {
    name: "Raissa Reinfeld",
    image: rr,
  },
  {
    name: "Karthiban Nithyakumar",
    image: kn,
  },
];

const StyledImg = styled("img")({});

export default function AboutUs() {
  const theme = useTheme();
  const { t } = useTranslation();

  const textPoints = t("aboutUs.aspiration.textPoints", {
    returnObjects: true,
  });

  const founderTexts = t("aboutUs.founder.texts", {
    returnObjects: true,
  });
  return (
    <>
      <Box
        sx={{
          paddingTop: "140px",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          backgroundImage: `url(${backgroundImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100%",
          paddingBottom: "50px",
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            [theme.breakpoints.up("lg")]: {
              width: "80%",
            },
          }}
        >
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <PageHeader
                borderColor="#029E52"
                color="#31499E"
                text={t("aboutUs.title")}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <StyledImg
              src={vision}
              alt="Vision"
              sx={{
                width: "45%",
                [theme.breakpoints.down("md")]: {
                  width: "75%",
                },
                paddingBottom: 2,
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                gutterBottom
                sx={{
                  fontSize: "28px",
                  fontWeight: "500",
                  color: "#432918",
                }}
              >
                {t("aboutUs.vision.title")}
              </Typography>
              <Typography
                gutterBottom
                sx={{
                  textAlign: "justify",
                  fontSize: "16px",
                  color: "#3B505C",
                  paddingBottom: "16px",
                  width: "60%",
                  [theme.breakpoints.down("md")]: {
                    width: "85%",
                  },
                }}
              >
                {t("aboutUs.vision.text")}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <StyledImg
              src={purpose}
              alt="Purpose"
              sx={{
                width: "50%",
                [theme.breakpoints.down("md")]: {
                  width: "75%",
                },
                paddingBottom: "16px",
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                p: 3,
              }}
            >
              <Typography
                gutterBottom
                sx={{
                  fontSize: "28px",
                  fontWeight: "500",
                  color: "#432918",
                }}
              >
                {t("aboutUs.purpose.title")}
              </Typography>
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "24px",
                  color: "#3B505C",
                }}
              >
                {t("aboutUs.purpose.quote1")}
                <br />
                {t("aboutUs.purpose.quote2")}
              </Typography>
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "24px",
                  fontWeight: "700",
                  color: "#3B505C",
                  paddingBottom: 4,
                }}
              >
                ― {t("aboutUs.purpose.quote3")}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                bgcolor: "#EFF6F2",
                width: "70%",
                [theme.breakpoints.down("md")]: {
                  width: "95%",
                },
                padding: 4,
                borderRadius: "16px",
              }}
            >
              <Typography
                gutterBottom
                sx={{
                  textAlign: "justify",
                  fontSize: "16px",
                  color: "#3B505C",
                }}
              >
                {t("aboutUs.purpose.text")}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {" "}
            <Typography
              gutterBottom
              sx={{
                fontSize: "28px",
                fontWeight: "500",
                color: "#432918",
                padding: 2,
              }}
            >
              {t("aboutUs.aspiration.title")}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <StyledImg
              src={aspiration}
              alt="Aspiration"
              sx={{
                padding: 2,
                height: "80%",
                [theme.breakpoints.up("md")]: {
                  height: "50%",
                },
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                textAlign: "left",
                fontSize: "14px",
                [theme.breakpoints.down("md")]: {
                  textAlign: "justify",
                  fontSize: "16px",
                },
                color: "#3B505C",
                lineHeight: "30px",
                width: "90%",
                p: 3,
              }}
            >
              {t("aboutUs.aspiration.subTitle")}
              <br />
              {textPoints.map((x) => (
                <React.Fragment key={x.substring(0, 10)}>
                  - {x}
                  <br />
                </React.Fragment>
              ))}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "28px",
                fontWeight: "500",
                color: "#432918",
                padding: 2,
              }}
            >
              {t("aboutUs.founder.title")}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar
              alt="Siva Subramaniyam"
              src={founder}
              sx={{
                width: 200,
                height: 200,
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box>
              <Typography
                sx={{
                  textAlign: "justify",
                  fontSize: "16px",
                  color: "#3B505C",
                  lineHeight: "30px",
                  p: 4,
                }}
              >
                <strong>{t("aboutUs.founder.subTitle")}</strong>
                <br />
                {founderTexts.map((x) => (
                  <React.Fragment key={x.substring(0, 20)}>
                    - {x}
                    <br />
                    <br />
                  </React.Fragment>
                ))}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "28px",
                fontWeight: "500",
                color: "#432918",
                padding: 2,
              }}
            >
              {t("aboutUs.volunteers.title")}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "60%",
              }}
            >
              <List
                dense
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                }}
              >
                {volunteers.map((volunteer) => {
                  const labelId = `volunteer-list-${volunteer.name}`;
                  return (
                    <ListItem key={volunteer.name}>
                      <ListItemAvatar>
                        <Avatar alt={volunteer.name} src={volunteer.image} />
                      </ListItemAvatar>
                      <ListItemText id={labelId} primary={volunteer.name} />
                    </ListItem>
                  );
                })}
              </List>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
