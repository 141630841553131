import { Box } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import Link from "@mui/material/Link";
import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import logoImage from "../assets/footer-logo.svg";
import { useTranslation } from "react-i18next";

const pages = [
  {
    name: "Home",
    path: "home",
  },
  {
    name: "Our Customers",
    path: "our-customers",
  },
  {
    name: "Our Offers",
    path: "our-offer",
  },
  {
    name: "About Us",
    path: "about-us",
  },
  {
    name: "Volunteers",
    path: "volunteers",
  },
  {
    name: "Guides",
    path: "guides",
  },
  {
    name: "Contact Us",
    path: "contact-us",
  },
];

export default function Footer() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  return (
    <footer>
      {/* <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#EB010A",
          height: "100px",
        }}
      >
        <Box sx={{}}>
          <img alt="Logo" src={logoImage} />
        </Box>
        <Box>
          <List
            sx={{
              display: "flex",
            }}
          >
            {pages.map((page) => (
              <ListItem
                key={page.path}
                sx={{
                  width: "140px",

                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Link
                  href={page.path}
                  component="button"
                  variant="body2"
                  underline={
                    location.pathname.slice(1) === "" ||
                    location.pathname.slice(1) === page.path
                      ? "always"
                      : "hover"
                  }
                  sx={{
                    color: "white",
                    fontSize: "0.8em",
                    "&.MuiLink-underlineAlways": {
                      textDecorationColor: "white !important",
                    },
                    "&.MuiLink-underlineHover": {
                      textDecorationColor: "white !important",
                    },
                    "&:hover": {
                      fontWeight: 600,
                    },
                    ...((location.pathname.slice(1) === "" ||
                      location.pathname.slice(1) === page.path) && {
                      fontWeight: 600,
                    }),
                  }}
                  onClick={() => navigate(page.path)}
                >
                  {page.name}
                </Link>
              </ListItem>
            ))}
          </List>
        </Box>
      </Box> */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#EB010A",
          height: "50px",
        }}
      >
        <Box
          sx={{
            fontSize: "12px",
            color: "white",
          }}
        >
          {t("footer.copyright", { year: new Date().getFullYear() })}
        </Box>
      </Box>
    </footer>
  );
}
