import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import backgroundImage from "../assets/Path1.svg";

import ss from "../assets/SelvakumarSubramaniam.svg";
import kn from "../assets/KarthibanNithyakumar.svg";
import kf from "../assets/KatharinaFlorian.svg";
import pd from "../assets/PradeeprajDevaraj.svg";
import rr from "../assets/RaissaReinfeld.svg";

const StyledImg = styled("img")({});

export default function Volunteers() {
  return (
    <>
      <Box
        sx={{
          paddingTop: "140px",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          backgroundImage: `url(${backgroundImage})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right center",
          backgroundSize: "500px",
          paddingBottom: "50px",
        }}
      >
        <Box
          sx={{
            fontSize: "40px",
            fontWeight: "700",
            color: "#31499E",
            borderBottom: "3px solid #FCD570",
            height: "60px",
            marginBottom: "16px",
          }}
        >
          Volunteers
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: 2,
                margin: 1,
              }}
            >
              <StyledImg src={kf} alt="Katharina Florian" />
              <Typography
                sx={{
                  fontWeight: "500",
                  color: "#2D3037",
                }}
              >
                Katharina Florian
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: 2,
                margin: 1,
              }}
            >
              <StyledImg src={pd} alt="Pradeepraj Devaraj" />
              <Typography
                sx={{
                  fontWeight: "500",
                  color: "#2D3037",
                }}
              >
                Pradeepraj Devaraj
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: 2,
                margin: 1,
              }}
            >
              <StyledImg src={ss} alt="Selvakumar Subramaniam" />
              <Typography
                sx={{
                  fontWeight: "500",
                  color: "#2D3037",
                }}
              >
                Selvakumar Subramaniam
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: 2,
                margin: 1,
              }}
            >
              <StyledImg src={rr} alt="Raissa Reinfeld" />
              <Typography
                sx={{
                  fontWeight: "500",
                  color: "#2D3037",
                }}
              >
                Raissa Reinfeld
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: 2,
                margin: 1,
              }}
            >
              <StyledImg src={kn} alt="Karthiban Nithyakumar" />
              <Typography
                sx={{
                  fontWeight: "500",
                  color: "#2D3037",
                }}
              >
                Karthiban Nithyakumar
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
