import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import movLogo from "../assets/MOV_Color_RGB.svg";
import { styled } from "@mui/material/styles";
import backgroundImage from "../assets/background.jpg";
import germanBusinessPlan from "../assets/32_MAP_Business_Idea_slide_02_German.svg";
import englishBusinessPlan from "../assets/32_MAP_Business_Idea_slide_02_English.svg";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { PageHeader } from "../components";
import { SelectedLangContext } from "../contexts/SelectedLangContext";

const StyledImg = styled("img")({});

export default function Home() {

  const selectedLangContext = React.useContext(SelectedLangContext);
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <>
      <Box
        sx={{
          paddingTop: "140px",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          backgroundImage: `url(${backgroundImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100%",
          paddingBottom: "50px",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            justifyContent: "center",
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{
              p: 3,
              [theme.breakpoints.up("lg")]: {
                width: "80%",
              },
            }}
          >
            <Grid item xs={12} md={8}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  p: "0px 16px",
                }}
              >
                <StyledImg
                  src={movLogo}
                  alt="Map of Vita"
                  sx={{
                    width: "75%",
                    mb: 2,
                  }}
                />
                <Box>
                  <Typography
                    gutterBottom
                    textAlign="left"
                    sx={{
                      fontSize: "40px",
                      fontWeight: "700",
                      [theme.breakpoints.down("sm")]: {
                        fontSize: "24px",
                        fontWeight: "400",
                      },
                      color: "#432918",
                    }}
                  >
                    <strong>{t("tagLine")}</strong>
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                  padding: 3,
                  height: "100%",
                }}
              >
                <Typography
                  gutterBottom
                  sx={{
                    fontSize: "24px",
                    fontWeight: "400",
                    color: "#432918",
                  }}
                >
                  <strong>{t("homePage.individualsTitle")}</strong>{" "}
                  {t("homePage.oneLiner.individuals")}
                </Typography>
                <Typography
                  gutterBottom
                  sx={{
                    fontSize: "24px",
                    fontWeight: "400",
                    color: "#432918",
                  }}
                >
                  <strong>{t("homePage.nobleInitiativesTitle")}</strong>{" "}
                  {t("homePage.oneLiner.nobleInitiatives")}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                [theme.breakpoints.up("md")]: {
                  mt: "15%",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <PageHeader
                  borderColor="#AEA59C"
                  color="#31499E"
                  text={t("homePage.subTitle")}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  padding: 2,
                  width: "80%",
                  [theme.breakpoints.down("md")]: {
                    width: "95%",
                  },
                }}
              >
                <Box
                  sx={{
                    flexGrow: 1,
                    bgcolor: "#F7F7F5",
                    display: "flex",
                    justifyContent: "center",
                    borderRadius: "16px",
                    p: 2,
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    gutterBottom
                    sx={{
                      p: 3,
                      textAlign: "justify",
                      fontSize: "16px",
                      color: "#3B505C",
                    }}
                  >
                    {t("homePage.desc1")}
                    <br />
                    <br />
                    <strong>{t("homePage.individualsTitle")}</strong>{" "}
                    {t("homePage.individualsDesc")}
                    <br />
                    <br />
                    <strong>{t("homePage.nobleInitiativesTitle")}</strong>{" "}
                    {t("homePage.InitiativesDesc")}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {selectedLangContext === "de" && (
                  <StyledImg
                    src={germanBusinessPlan}
                    alt="German Business Plan"
                    sx={{
                      p: 2,
                      width: "100%",
                      borderRadius: "15px",
                    }}
                  />
                )}
                {selectedLangContext === "en" && (
                  <StyledImg
                    src={englishBusinessPlan}
                    alt="English Business Plan"
                    sx={{
                      p: 2,
                      width: "100%",
                      borderRadius: "15px",
                    }}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
